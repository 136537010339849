/* -------------------- General Site layouts -------------------- **/
*{
	font-family: 'Roboto', sans-serif;
	color: #000;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

button, a, input[type=submit]{
   -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
}

.fancybox-overlay {
    z-index: 9998;
}
.fancybox-wrap {
    z-index: 9999;
}

.spinner{
	display: none;
    position: absolute;
    bottom: -50px;
    width: 40px;
    left: 50%;
    @include transform(translateX(-50%));
}

body{
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}

#MenuIcon{
	display: none;
}

/*** Calendar ***/


.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid #009b49 !important;
	color: #009b49 !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
	margin-bottom: 10px;
	background-color: rgba(207, 205, 212, 0.81);
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
    &:hover{
    	background-color: rgba(207, 205, 212, 1) !important;
    }
}
.calendar-widget-table{
	width: 100%;
}
.calendar-widget-table tbody td {
    background-color: transparent;
    padding: 20px 0px !important;
    @include transition($t1);
    text-align: center !important;
    width: 13%;
    font-size: 1em;
}



.calendar-widget-table .next,
.calendar-widget-table .prev{
    position: absolute;
  
    font-weight: lighter;
    font-size: 1.3em;
    width: 40px;
    height: 40px;
    background-color: #009b49;
    text-align: center;
    border-radius: 50%;
    @include transition($t1);
}
.calendar-widget-table .prev{
    left: 0px;
    top: 3px;
    color: #fff !important;
}
.calendar-widget-table .next{
	right: 0px;
    top: 3px;
	color: #fff !important;
}
.calendar-widget-table .next,
.calendar-widget-table .prev{
	&:hover{
		background-color: #5a5962 !important;
		color: #fff !important;
	}
	i{
		color: #fff !important;
	}
}
.show-month{
    font-weight: lighter;
    padding-bottom: 20px;
    display: block;
    color: #009b49 !important;
}

.calendar-widget {
    position: relative;
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: #009b49;
    	@include transition($t1);
    	@include border-radius(3px);
    	&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}
	}
}

.calendar-widget-table tbody .hasEvent{
	background-position: 4px 4px !important;
	background-image: url(../images/dot.png);
}
.calendar-widget-table table td, .calendar-widget table th{
	border: none !important;
}
.calday .day{
	font-style: italic;
	padding: 10px;
	display: inline-block;
	min-width: 40px;
    text-align: center;
	    background-color: #009b49;
	    *{color: #fff !important;}
	    color: #fff !important;
}
.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-day:hover, .calendar-widget tbody .show-week:hover, .calendar-widget tbody .selected {
    background-color: rgba(0, 155, 73, 0.18);
    cursor: pointer;
    color: #009b49;
}

.calendar-widget-table tbody .today {
    font-weight: 700;
    color: #009b49;
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: #009b49;
}
/*** Calendar ***/



/*** Page ***/
.Page_results,
.InnerPage{
	.banner{
		height: 66vh;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}




.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: #009b49;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}

.CalReadMore{
	display: none;
}


.calendar-widget-table tbody .calendar-header td{
	
	    background-color: #009b49;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #dedede;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #d92d2a;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}

.MainMenuClose{
	display: none !important;
}

.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.addToCalendar,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    font-weight: lighter; 
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: #009b49;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: #009b49;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
	    	    background-color: #4b4b4b !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}


/*
.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
*/

.heading-2{
		color: #009b49 !important;
	*{
		color: #009b49 !important;
	}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: #009b49 !important;

		 &:hover{
	    	    background-color: #4b4b4b !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 20%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.InnerPage{
    .calPrev,
    .calNext {
        top: 37px;
    }
    .calendar-widget-table,
    .outerCalendarContainer{
        width: 100%;
    }
    .eventContainer{
        display: none !important;
    }
    .calHeader{
        .col-7,
        .col-5{
            width: 100%;
            text-align: center;
        }
        .col-5{
            display: none !important;
        }
    }
}
.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{    
	min-height: 650px;
}


/*
.searchToggle{
	right: 69px;
	@include transition($t1);
}

.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}

.searchToggle{
	position: absolute;
	z-index: 9999;
	top: 0px;
    cursor: pointer;
	right: 0px;
	height: 41px;
	width: 40px;
	text-align: center;
	padding-top: 8px;
	font-size: 1.1em;
	opacity: 1;
	*{
		color: #dbb95a;
	}
}
*/

/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(41,37,89,0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}


/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #0688a5;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #01718a;
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #01718a;
}


.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/img5.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
}


#google_translate_element{
    border: 30px solid #312d5e;
}



/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}
/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
}


/*** Menu ***/
#Menu{
	float: left;
    z-index: 9999;
    width: 100%;
    text-align: center;
	display: inline-block;
	text-align: right;
	*{
		color: #fff;
	}
	/*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
	.MenuContainer{
    	text-align: center;
		position: relative;
		.LevelOne{
			display: inline-block; 
			text-align: center;
			position: relative;
			a{    
				font-size: 1em;
				@include transition($t1);
    			padding: 14px 20px 14px 20px;
			    font-size: 1.1em;
			    line-height: 3em;
			    display: inline-block; 
	   	 		line-height: 1.1em; 
	   	 		opacity: 1;
	   	 		&:hover{
	   	 			opacity: 0.5;
	   	 		}
			}
		}
	}


	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 250px;
		height: 0px;
		font-size: 0.7em;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	text-align: left;
		position: absolute;
	    background-color: #4b4b4b;
	    border-radius: 0px;
	    margin-top: 0px;
	   /* padding: 3px 10px 6px 10px;*/
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) translateX(-50%) scale(0.98));

	    a{
		    display: inline-block;
		    padding: 10px 20px !important;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;
		    font-weight: bold;
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			background-color: rgba(255,255,255,0.2) !important;
			}
		}
	}
	.menuItem-1,
	.menuItem-2{
		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.menuItem-Last{
		.SubMenu{
			left: auto;
			right: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) scale(1) !important);
	}
	.menuItem-1.active .SubMenu,
	.menuItem-2.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(0%) scale(1) !important);
	}
	.menuItem-Last.active .SubMenu{
			opacity: 1 !important;
			left: auto;
			right: 0%;
			@include transform(translateY(0px) translateX(0%) scale(1) !important);	
	}

	/*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}

.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}
/* -------------------- General Site layouts -------------------- **/




/* -------------------- Site Build -------------------- **/

.Ernest{
	.btnSection{
		*{
			color: #e43033;
		}
	}
}
.Eileen{
	.btnSection{
		*{
			color: #009b49;
		}
	}
}
.Ernest.SchoolSection{
	border-bottom: 5px solid #e43033;
}
.Eileen.SchoolSection{
	border-bottom: 5px solid #009b49;
}
.SchoolSection{
	

	min-height: 100vh;
	border-bottom: 5px solid #000;
	background-color: #f7f7f7;
	.logo{
		max-width: 50% !important;
	}
}
.SocialSection{
	padding:  20px;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	font-size: 1.5em;
	a{
		@include transition($t1);
		display: inline-block;
		padding: 0px 6px;
		&:hover{
			opacity: 0.5 !important;
		}
	}
}

.LogoSection{
	position: absolute;
    top: 50%;
    left: 50%;
    @include transform($center);
}
.btnSection{
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding: 40px 20px;
	a{
		display: block;
		*{
			display: inline-block;
			vertical-align: middle;
			text-transform: uppercase;
		}
		img{
			height: 25px;
			margin-left: 20px;
		}
	}
}
.bold{
	font-weight: bold;
}

.WelcomeHeading{
	padding: 10px;
	background-color: #f7f7f7;
	font-weight: bold;
	font-size: 1.4em;
	text-transform: uppercase;
}
.WelcomeImage{
	padding: 20px;
}
.WecomeImageInner{
	padding: 10px;
	padding-bottom: 60%;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}
.WelcomeSubheading{
	font-size: 2em;
	font-weight: lighter;
	padding: 25px;
	text-transform: uppercase;
}
.WelcomeTextOne{
	font-size: 1.3em;
	*{font-size: 1em;
		font-weight: bold;
		color: #027d98;
	}
}
.WelcomeTextTwo{
	font-size: 1.3em;
	color: #027d98;
	*{color: #027d98;font-size: 1em;}
}
.WelcomeSectionInner{
	padding: 10px;
	overflow-x: hidden;
	overflow-y: auto;
	/*max-height: calc(100vh - 40px);*/
}
.WelcomeSection{
	min-height: 100vh;
}

.WelcomeSectionInner::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.WelcomeSectionInner::-webkit-scrollbar-button:start:decrement,
.WelcomeSectionInner::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.WelcomeSectionInner::-webkit-scrollbar-track-piece{
	background-color: #eee;
	@include border-radius(0px);
}
.WelcomeSectionInner::-webkit-scrollbar-thumb{
	background-color: #aaa;
	@include border-radius(0px); 
}
.WelcomeSectionInner::-webkit-scrollbar-thumb:vertical{
}
.WelcomeSectionInner::-webkit-scrollbar-thumb:horizontal{
}
.WelcomeSectionInner::-webkit-scrollbar-thumb:hover{
	background-color: #aaa;
}

#Home2{
	padding: 100px 20px 180px 20px;
	.EventsHeader{
		padding-bottom: 40px;
		img{    
			width: 93px;
   		 	max-width: 25%;
    		margin-bottom: 20px;
		}
		*{text-transform:uppercase; font-size: 2em; font-weight: lighter;}
	}
}
.SplashPageEvent{
	padding: 20px;
	border-bottom: 1px solid #000;
	a{
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
	}
}
.CalDate{
	position: absolute;
	left: 20px;
	top: 50%;
	text-align: left;
    font-size: 1.3em;
	@include transform(translateY(-50%));
	sup{
		font-size: 0.7em;
	}
}
.eventContent{
	padding-left: 80px;
}
.CalEventTitle{
	padding-bottom: 20px;
	&:after{
		content: '';
		display: block;
		height: 1px;
		width: 20%;
		left: 0px;
		position: absolute;
		bottom: 10px;
	}
}
.eventText{

}
.eventTime{
	padding-top: 10px;
	font-size: 0.7em;
}

.LeftCal{
	.CalEventTitle{
		&:after{
			background-color: #e43033;
		}
	}
	.CalDate,
	.CalEventTitle{
		color: #e43033;
		*{color: #e43033;}
	}
	.SplashPageEvent{
		border-bottom: 1px solid #e43033;
	}
}

.EileenWardHomePage,
.RightCal{
	.CalEventTitle{
		&:after{
			background-color: #009b49;
		}
	}
	.CalDate,
	.CalEventTitle{
		color: #009b49;
		*{color: #009b49;}
	}
	.SplashPageEvent{
		border-bottom: 1px solid #009b49;
	}
}


.fseFooter{
	text-align: center;
	font-size: 0.8em;
	padding: 20px;
	color: #fff;
	background-color: $themeColor;
	*{
		font-size: 1em;
		color: #fff;
	}
}

.footerIcon{
	background-color: $themeColor;
	height: 50px;
	width: 50px;
	display: inline-block;
	position: relative;
	overflow: hidden;
	margin: 10px;
	@include border-radius(50%);
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	img{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 25px;
		@include transform($center);
	}
}

.UsefulLinks{
	a{
	    padding: 10px 20px;
	    color: #000000;
	    font-size: 0.7em;
	    display: inline-block;
	}
}
.pageTitle{
	font-size: 2em;
	font-weight: lighter;
	text-transform: uppercase;
}
.mapContact{
	position: absolute;
	top: 50%;
	margin-top: -20px;
	height: calc(100% + 40px);
	left: 10%;
	padding: 20px;
	@include transform(translateY(-50%));
	border-top: 2px solid $themeColor;
	background-color: #fff;
	width: 100%;
	max-width: 450px;

	.pageTitle{
		padding: 20px;
		text-align: center;
	}
	.contactInfo{
		border-top: 1px solid #000;
		*{
			font-weight: lighter;
		}
	}
	.contactInfoTitle{
		padding: 20px 0px;
		font-size: 1.5em;
		font-weight: lighter;
		text-transform: uppercase;
	}
}

.HomePage #MenuIcon{
	display: none !important;
}

#header{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 9900; 
	padding-top: 18px;
	@include transition($t1);
}
.homebtn{
	position: absolute;
	top: 20px;
	left: 30px;
	@include transition($t1);
	img{

	    width: 220px;
		@include transition($t1);
	}
}

.scroll{
	position: absolute;
	bottom: 18vh;
	left: 50%;
	width: 90px;
	display: block;
	z-index:90;
	@include transform(translateX(-50%));
}

.bottomAngleInner,
.bottomAngle2,
.bottomAngle{
	z-index: 90;
	position: absolute;
	background-color: #fff;
	width: 100%;
	bottom: -1px;
	left: 0px;
	height: 25%;
	-webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
	clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.bottomAngleInner{
	display: none;
}
.Page_results .bottomAngleInner,
.InnerPage .bottomAngleInner{
	display: block !important;
}
.bottomAngleInner{
	background-color: #f5f5f5;
}
.bottomAngle2{

	height: calc(25% + 8px);
	background-color: #009b49;
	-webkit-clip-path: polygon(0 96%, 100% 0, 100% 100%, 0% 100%);
	clip-path: polygon(0 96%, 100% 0, 100% 100%, 0% 100%);
}

.EileenWardHomePage .banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 70vh;
	@include background-cover(url("../images/img5.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

#InnerHome1{
	padding: 100px 0px;
}
#InnerHome2{
	background-position: center;
	background-size: cover;
	height: 600px;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	background-image: url(../images/bg.jpg);
}
.topAngle{
	background-color: #fff;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 10%);
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 10%);
	height: 200px;
}

.bottomAngle3{
	background-color: #f5f5f5;
	position: absolute;
	left: 0px;
	bottom: -1px;
	width: 100%;
 	-webkit-clip-path: polygon(0 91%, 100% 0, 100% 100%, 0 100%);
	clip-path: polygon(0 91%, 100% 0, 100% 100%, 0 100%);
	height: 100px;
}

.ReadMore{
	overflow: hidden;
	font-size: 1.4em;
	display: inline-block;
	padding: 10px 96px;
	@include transition($t1);
	color: #fff;
	background-color: #000;
	font-weight: lighter;
	text-transform: uppercase;
	position: relative;
	&:before{
		content: '';
		display: block;
		position: absolute;
		height: 103%;
		width: 0%;
		left: 50%;
		top: 50%;
		z-index: 0;
		background-color: $themeColor;
		@include transform($center);
		@include transition($t1);
	}
	&:hover{
		&:before{
			width: 102%;
		}
	}
	span{
		color: #fff;
		z-index: 1;
		font-weight: lighter;
		text-transform: uppercase;
		position: relative;
	}
}

#InnerHome3{
	padding: 100px 0px;
	background-color: #f5f5f5;
}


.homeIcons{
	margin-top: 40px;
	text-align: center;
	max-width: 422px;
    min-width: 0px;
	img{
		height: 80px;
	}
	span{
		display: block;
		width: 100%;
		padding-top: 20px;
		text-transform: uppercase;
	}
	.col-4:first-of-type span{
		color: #e13339;
	}
	.col-4:nth-child(2) span{
		color: $themeColor;
	}
	.col-4:last-of-type span{
		color: #009b49;
	}
}

.NewsImg{
	padding-bottom: 50%;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}

.NewsBox{
	background-color: #fff;
	@include transition($t1);
	@include box-shadow(0px 0px 20px 0px rgba(0,0,0,0.2));
	&:hover{
		@include box-shadow(0px 0px 30px 0px rgba(0,0,0,0.4));
	}
}

.NewsHeadingBox{
    margin-top: -250px;
}
.NewsHeadingBoxInner{
    background-color: #fff;
    border-bottom: 3px solid #009b49;
    padding: 20px;
    min-height: 489px;
}

.NewsCircle{
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
	height: 60px;
	width: 60px;
	display: inline-block;
	vertical-align: middle;
	@include border-radius(50%);
}
.newsBottomText{
	padding-left: 20px;
	display: inline-block;
	vertical-align: middle;
}
.newsContent{
	font-size: 0.8em;
}

#InnerHome4{
	padding: 100px 20px;
}
.FaceBoxInner,
.twitterBoxInner{
	font-size: 1.4em;
	border: 1px solid #009b49;
	padding: 30px;
	.readMoreInner{ 
		display: block;
		margin-top: 30px;
		width: 100%;
		font-size: 0.8em;
		text-transform: uppercase;
		*{
			display: inline-block;
			vertical-align: middle;
		}
		span{
			padding: 10px;
		}
		img{
			top: 62%;
			@include transform(translateY(-50%));
			position: absolute;
			right: 10px;
			width: 70px; 
			@include transition($t1);
		}
		&:hover{
			img{
				right: 0px;
			}
		}
	}

}
.FaceBoxInner{

	.readMoreInner:before{
		content: '';
		position: absolute;
		display: block;
		width: 70%;
		top: 18px;
		height: 1px;
		background-color: #fff;
		left: 50%;
		@include transform(translateX(-50%));
	}
	background-color: #009b49;
}

.InnerPage,
.EileenWardHomePage{
	.footerIcon,
	.fseFooter{
		background-color: #009b49;
	}
	.UsefulLinks a{
		color: #009b49;;
	}
}

.topLinks{
	opacity: 1;
	z-index: 9999;
	position: fixed;
	top: 20px;
	right: 20px;
	@include transition($t1);
	color: #fff;
	*{
		color: #fff;
		display: inline-block;
	}
	.link{
		padding: 10px;
		font-size: 1.4em;
	}
}

.SearchContainer{
	    width: 228px;
    display: none;
    position: absolute;
    bottom: 2px;
    left: auto;
    right: 0px;
    padding: 4px 4px 0px 4px;
    background-color: #027d98;
    @include transform(translateY(100%))

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

     input[type=submit]{
     float: left;
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #56b4b9;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
	    float: left;
    	display: block;
    }
}

.searchToggle{
	cursor: pointer;
	padding-right: 30px; 
	margin-right: 10px;
	border-right: 1px solid #fff;
}
.searchToggle.link{
	padding-right: 20px; 
}

.ScrollNav{
	#header{
		padding-top: 0px;
		background-color: $themeColor;
	}

	.homebtn{
		top: 0px;
		img{    
			width: 120px;
		}
	}

	.topLinks{
    	top: -2px;
	}
}

.NewsBox{
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.PageContainer{
	z-index: 100;
	background-color: #f5f5f5;
}

#sidebar {
	background-color: #fff;
    padding: 50px 45px;  
    margin-top: -150px !important;
    margin-bottom: 0px !important;
    font-size: 1.3em;
    *{font-size: 1em;}
}
.PageInner{
	position: relative;
	margin: 0 auto;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	max-width: 1850px;
}
.innerContent .pageTitle{
	color: #009b49;
	font-weight: bold;
	text-transform: initial !important;
}


/*
|===============================================================
|	Uploads
|===============================================================
*/
.attachment,
.upload{
overflow: hidden;
cursor: pointer;
		@include transition($t1);
	display: inline-block;
	width: 100%;
	max-width: 500px;
	padding: 10px 20px;
	text-transform: uppercase;
	border: 2px solid #ededed;
	color: #2b2b2b;
	padding-left: 50px;
	position: relative;
	&:before{
		@include transition($t1);
		content: '\f019';
		display: block;
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		opacity: 0;
		left: 10px;
			color: #009b49;
		*{
			color: #009b49;
		}
		@include transform(translateY(-100%));
	}
		color: #009b49 !important;
	.uploadTitle{
		color: #009b49;
	}
	&:after{
		@include transition($t1);
		display: block;
		content: '\f0f6';
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		left: 10px;
		opacity: 1;
			color: #009b49;
		*{
			color: #009b49;
		}
		@include transform(translateY(-50%));
	}
	&:hover{

			color: #009b49;
		border: 2px solid #009b49;
		&:before{
			color: #009b49;
			opacity: 1;
			@include transform(translateY(-50%));
		}
		&:after{
			color: #009b49;
			opacity: 0;
			@include transform(translateY(50%));
		}
	}

	.fileIcon{
		display: none;
	}
}




.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}

.news-teaser h3{
	color: #009b49;
    font-weight: bold;
    font-size: 1.5em;
}


#SearchResults{
	h2 a{ 
		color: #009b49 !important;
	}
}
 .bio h4,
 .bio h3{
 color: #009b49
 }

/*** Milton Earnest #e43033 ***/
.MiltonErnestHomePage{
#SearchResults h2 a{
	
		color: #e43033 !important;
}
	.bottomAngle2 {
	    background-color: #e43033; 
	}
	.bottomAngleInner {
		display: none !important;
	}

	.FaceBoxInner {
	    background-color: #e43033;
	}
	.FaceBoxInner, .twitterBoxInner {
	    font-size: 1.4em;
	    border: 1px solid #e43033;
	    padding: 30px;
	} 
	/*.ReadMore:hover {
	    background-color: #e43033;
	}*/
 
	.fseFooter, .footerIcon{
	    background-color: #e43033;
	}
	.UsefulLinks a{
		color: #e43033;
	}
	.CalEventTitle,
	.CalDate{
		color: #e43033;
		*{color: #e43033;}
	}
	.SplashPageEvent{
		border-bottom-color: #e43033;
	}

}
.MiltonErnestHomePage{
	.banner{
		height: 70vh;
	}
}
.MiltonErnestPage{
	.innerContent .pageTitle {
		color: #e43033 !important;
	}

	#SearchResults h2 a{
	
		color: #e43033 !important;
}
	.calendar-widget-table tbody .calendar-header td{
		background-color: #e43033; 
	}
	.calendar-widget-table .next, .calendar-widget-table .prev{
		background-color: #e43033; 
	}
	.bottomAngle2 {
	    background-color: #e43033; 
	}
	.FaceBoxInner {
	    background-color: #e43033;
	}
	.FaceBoxInner, .twitterBoxInner {
	    font-size: 1.4em;
	    border: 1px solid #e43033; 
	    padding: 30px;
	}/* 
	.ReadMore:hover {
	    background-color: #e43033;
	}
	 */
	.ReadMore:before{
		background-color: #e43033;
	}

	.fseFooter, .footerIcon{
	    background-color: #e43033;
	}
	.calendar-widget-table tbody .calendar-header td {
	    background-color: #e43033;
	}
	.addToCalendar, .readMoreLink, .button,
	.toggleContent .url ,
	.calday .day,
	.calendar-widget-table .next, .calendar-widget-table .prev{
	    background-color: #e43033;
	}
	.calendar-widget-table tbody .today {
	    color: #e43033;
	}

	.UsefulLinks a{
		color: #e43033;
	}

	.show-month {
	    color: #e43033 !important;
	}
	.CalendarFilter span.active {
	    border-bottom: 2px solid #e43033 !important;
	    color: #e43033 !important;
	}
	.calendar-widget-table tbody .calendar-day:hover, .calendar-widget tbody .show-week:hover, .calendar-widget tbody .selected {
	    background-color: rgba(228, 48, 51, 0.41);
	    cursor: pointer;
	    color: #e43033;
	}
	.news-teaser h3 {
	    color: #e43033;
	}

	.attachment:after, .upload:after,
	.attachment:before, .upload:before,
	.heading-2{ 

	    color: #e43033 !important;
		*{
		    color: #e43033 !important;
		}
	}

	.bio h4, .bio h3 {
	    color: #e43033 !important;
	}

	.heading-2 * {
	    color: #e43033 !important;
	}

	.attachment, .upload {
		color: #e43033 !important;
		*{
		    color: #e43033 !important;
		}

	}
	.attachment:hover, .upload:hover {
	    color: #e43033 !important;
	    border: 2px solid #e43033 !important;
	}
}

.SchoolSection{
	overflow: hidden;
	.HoverImage{
		@include transform($center scale(1));
		@include transition(all 10s);
		position: absolute;
		top: 50%;
		left: 50%;
		height: 100%;
		width: 100%;
		opacity: 0.15;
		background-size: cover;
		background-position: center;
		background-image: url(../images/bg.jpg);
	}
	&:hover .HoverImage{
			@include transform($center scale(1.4));
	}
}

.EileenWardPage.ScrollNav{
	#header {
	    padding-top: 0px;
	    background-color: #027d98;
	}
}

.EileenWardPage.ScrollNav{
	#header {
	    padding-top: 0px;
	    background-color: #009b49 !important;
	}
}

.MiltonErnestPage.ScrollNav{
	#header {
	    padding-top: 0px;
	    background-color: #e43033 !important;
	}

	
}

.MiltonErnestPage{

	#sidebar button:hover,
	#sidebar ul li a:hover span {
	    color: #e43033 !important;
	}
}

.EileenWardPage{
	.ReadMore:before{
		background-color: #009b49;
	} 
	#sidebar button:hover,
	#sidebar ul li a:hover span {
	    color: #009b49 !important;
	}
}
.twitterBoxInner .match,
.FaceBoxInner .match{
 /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.ContactForm{
	#content input[type=submit],
	#content input[type=text], #content input[type=email], #content input[type=password], #content textarea{
		width: 100% !important;
	}
	#content label {
		font-weight: bold !important;
	}
}


.SplashPageEvent,
.eventFilter{
	@include transition($t1);
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	background-color: transparent !important;
	&:hover{
		background-color: #efefef !important;
	}
}
/*
#allEvents ul li:hover, #HomepageEvents ul li:hover{
		background-color: transparent !important;
}
*/
.calEventInner{
	position: relative;
	padding: 15px 20px 15px 100px;
	border-bottom: 1px solid #000;
	.CalDateInner{
		position: absolute;
		left: 20px;
		top: 50%;
		font-size: 1.4em;
		@include transform(translateY(-50%));
		sup{
			font-size: 0.7em;
		}
	}
	.CalContentInner{

	}
	.CalInnerTitle{
		padding-bottom: 20px;
		&:before{
			content:'';
			position: absolute;
			bottom: 10px;
			left: 0px;
			width: 20%;
			height: 1px;
			background-color: #000;
		}
	}
	.CalInnerContent{

		font-size: 0.8em;
		p{
			margin-bottom: 0px !important;
		}
	}
	.CalInnerTimes{
		font-size: 0.6em;
	}
	.Calurl{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.MiltonErnestPage{
	.calEventInner{
		border-bottom: 1px solid #e43033;
		.CalDateInner{
			*{
				color: #e43033;
			}
		}
		.CalInnerTitle{

				color: #e43033;
			*{
				color: #e43033;
			}
			&:before{
				background-color: #e43033;
			}
		}
	}
}
.EileenWardPage{
	.calEventInner{
		border-bottom: 1px solid #009b49;
		.CalDateInner{
			*{
				color: #009b49;
			}
		}
		.CalInnerTitle{

				color: #009b49;
			*{
				color: #009b49;
			}
			&:before{
				background-color: #009b49;
			}
		}
	}
	
}

#destopEvents{
	display: block;
}

#MobEvents{
	display: none;
}

.HiddenEvents{
	display: none;
}
.HiddenEvents.active{
	display: block !important;
}
.toggleEvents{
    padding: 10px 20px;
    border: none;
    background-color: #000;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    cursor: pointer;
}

.FullLink{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.SocialSection {
    z-index: 90;
}

.rjplogo{
	*{
		display: inline-block;
		vertical-align: middle;
	}
	img{
		height: 15px;
	}
}

.banner .darken,
.darken{
	display: none !important;
} 
/* -------------------- Site Build -------------------- **/







