
/* ------------  General Site Responsive ----------- */
@media all and (min-width: 1201px)  {
	#MobileNavigation{
		display: none !important;
	}
}
@media all and (max-width: 1200px)  {
	#MenuIcon{
		display: block !important;
	}
	#Menu{
		display: none !important;
	}

	#header {
	    min-height: 70px;
	}
	.homebtn {
	    top: 12px;
	}
	.ScrollNav .homebtn {
	    top: 12px;
	}
	#MenuIcon {
		top: 0px;
	    @include transition($t1);
	}
	.topLinks {
	    top: 9px;
	    right: 100px;
	}
	.ScrollNav .topLinks {
	    top: 8px;
	}

	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
	.tacMid{
		text-align: center !important;
	}
}

@media all and (max-width: 1024px)  {
	.flex{
		display: block;
	}
}

@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 50% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */


/* ------------  Responsive ----------- */


@media all and (max-width: 1220px)  {
	.SocialClear{
		width: 100% !important;
	}
}

@media all and (max-width: 520px)  {
	#InnerHome1{
		text-align: center;
	}
	.eventContent{
		text-align: left !important;
	}
	.homeIcons{
		.col-4{
			width: 100%;
			padding-bottom: 40px;
		}
	}
}
@media all and (max-width: 680px)  {
	#InnerHome4 .col-6{
		width: 100% !important;
	}
	#InnerHome4 .pageTitle{
		text-align: center;
		padding-top: 30px;
	}
}


@media all and (max-width: 1030px)  {
	#InnerHome1{
		.col-6{width: 100%;}
		.homeIcons{
			margin-bottom: 50px;
		}
	}
}
@media all and (max-width: 1160px)  {
	.SchoolSection{
		width: 50%;
		height: 100vh;
		min-height: 700px;
	}
	.WelcomeSection{
		display: none;
	}
	#mobileWelcome{
		display: none !important;
	}
}
@media all and (max-width: 790px)  {
	.mapContact{
		text-align: center;
	    position: relative;
	    float: left;
	    top: 0%;
	    @include transform(translate(0%,0%));
	    width: 100%;
	    left: auto;
	    max-width: none;
	    margin-top: 0px;
	}
}
@media all and (max-width: 740px)  {

	.ClearMidIpad{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
	.RightCal,
	.LeftCal{
		clear: both !important;
		width: 100%;
	}

	#destopEvents{
		display: none !important;
	}

	#MobEvents{
		display: block !important;
	}

	#Home2 {
	    padding: 100px 20px 10px 20px;
	}
}


@media all and (min-width: 876px)  {
	.NewsBoxMatch{
		height: auto !important;
		min-height: auto !important;
	}
}
@media all and (max-width: 960px)  {
	.NewsHolder .NewsBoxContainer{
		width: 50%;
	}
}
@media all and (max-width: 875px)  {
	#InnerHome3{
		.col-4{
			width: 100% !important;
		}
		.NewsBoxContainer{
			width: 50%;
			min-width: 0px !important;
			clear: none !important;
		}
	}
	.NewsHeadingBox {
	    margin-top: 0px !important;
	}
	.NewsHeadingBoxInner{min-height: auto !important;}
}

@media all and (max-width: 640px)  {
	.topLinks{
		display: none;
	}
}
@media all and (max-width: 750px)  {
	.SchoolSection{
		width: 100% !important;
		clear: both !important;
	}
	.SchoolSection{
		min-height: 392px !important;
		height: 50vh !important;
	}
	.SchoolSection .logo {
	    max-width: 21% !important;
	}
}
@media all and (max-width: 570px)  {
	.NewsBoxContainer{
		width: 100% !important;
	}
	.scroll {
    	width: 56px;
	}
}

@media all and (max-width: 1024px)  {
	.bottomAngleInner, .bottomAngle2, .bottomAngle{
	    height: 15%;
	}
	.bottomAngle2 {
    	height: calc(15% + 8px);
    }
    .scroll{
    	display: none !important;
    }
    *{
    	background-attachment: initial;
  	  	-webkit-background-attachment: initial;
    }
    .ReadMore{
    	font-weight: normal;
    	*{font-weight: normal;}
    }
}

@media all and (max-width: 600px)  {
	.bottomAngleInner, .bottomAngle2, .bottomAngle{
	   display: none !important;
	}
	.Page_results .bottomAngleInner, .InnerPage .bottomAngleInner {
	    display: none !important;
	}
	.PageInner {
	    padding-top: 20px;
	}
}

@media all and (max-width: 550px)  {

	
	.ReadMore {
    	padding: 10px 20px;
    }
    #Home2 {
    	padding: 100px 20px 50px 20px;
	}

	.HideMob{
		display: none !important;
	}
	#InnerHome1{
		.ReadMore{
			margin-bottom: 20px !important;
		}
	}
	#InnerHome4{
		.col-6 .SocialClear{
			display: none !important;
		}

		.col-6 .SocialClear:first-of-type{
			display: block !important;
		}
	}
	#InnerHome2{
		display: none !important;
	}
}
@media all and (max-width: 440px)  {
	.NewsletterItem{
		width: 100% !important;
	}
	.homebtn img { 
    	    width: 120px;
	}
	.homebtn {
	   top: 12px;
	}
	.PageInner {
	    padding-top: 93px;
	}

	.banner{
    	display: none !important;
	}

	#header{
		padding-top: 0px;
		background-color: $themeColor;
	}

	.homebtn{
		img{
    	    width: 120px;
		}
	}

	.topLinks{
    	top: -2px;
	}
	.EileenWardPage{
	#header {
	    padding-top: 0px;
	    background-color: #009b49 !important;
	}
	}

	.MiltonErnestPage{
	#header {
	    padding-top: 0px;
	    background-color: #e43033 !important;
	}
	}
}
/* ------------  Responsive ----------- */
