/*** Mobile Screens ***/
#sidebar{
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  float: right;
  position: relative;
   font-size: 0.94em;
  *{
    text-transform: uppercase;
    font-weight: lighter;
  }

  .Level-1{
    display: none;
    ul{
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 12px;
    border: none;
    outline: none !important;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: $textDark;
    &:hover{
      color: $themeColor;
    }
  }
  .sidebar-heading{
    display: block;
    float: left;
    clear: both;
    width: 100%;
    a{
      padding: 5px 30px 5px 0px;
      border-bottom: 1px solid #dadada;
      display: block;
      text-decoration: none;
      color: $textDark;
      font-weight: 800 !important;

    }
  }
  ul{
    ul{
      display: none;

      li{
        a{
          /*** Level Indentation ***/
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      a{
        border-bottom: 1px solid #dadada;
        display: block;
        padding: 15px 30px 15px 0px;
        text-decoration: none;
        @include transition($t1);
        background-color: rgba(0,0,0,0.0);

        span{
          color: $textDark;
          @include transition($t1);
        }
        &:hover{
          span{
            color: $themeColor !important;
          }
        }
      }
    }
  }
}
.hideSideBarToggle{
    right: 45px !important;
    top: 49px !important;
}

#sidebar .sidebar-heading a {
    padding: 5px 30px 10px 0px !important;
}
#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
}

/*** Desktop Screens ***/
@media all and (max-width: 1200px) {
  #sidebar {
    margin-top: 0px !important;
  }
}
@media all and (min-width: 1201px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
